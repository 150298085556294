<template lang="pug">
include ../pug/svg
section#style
	Form(ref="styleForm" :validation-schema="styleSchema" v-slot="{ values, errors }")
		div.wrapper
			h2.sub Brand {{terminology('media','media','plural')}}
		div.wrapper.media
			div.c2
				div.field.upload.logo.colour(:class="{on:logoColourUrl}")
					button.bin.but.circ(v-if="logoColourUrl" type="button" @click="onMediaBin('dropzoneColour')")
						+svg(svg-filename="iconBin" aria-hidden="true" alt="Bin icon")
					figure
						img(v-if="logoColourUrl" :src="logoColourUrl" alt="")
						figcaption
							strong(data-label="Logo / Colour") Logo / Colour
							span Accepts: SVG or PNG ({{maxFileSizeTidy}})
							+svg(svg-filename="iconUpload" aria-hidden="true" alt="Upload icon")
					DropZone(
						ref="dropzoneColour"
						:paramName="dzOpts.paramName"
						:url="dzOpts.url"
						:withCredentials="dzOpts.withCredentials"
						:uploadOnDrop="dzOpts.uploadOnDrop"
						:multipleUpload="dzOpts.multipleUpload"
						:maxFiles="dzOpts.maxFiles"
						:parallelUpload="dzOpts.parallelUpload"
						:acceptedFiles="dzOpts.acceptedFiles.logo"
						:maxFileSize="maxFileSize"
						@addedFile="onLogoColourAdd"
						@sending="onLogoColourSend"
					)
				div.field.upload.logo.whiteout(:class="{on:logoWhiteoutUrl}")
					button.bin.but.circ(v-if="logoWhiteoutUrl" type="button" @click="onMediaBin('dropzoneWhiteout')")
						+svg(svg-filename="iconBin" aria-hidden="true" alt="Bin icon")
					figure
						img(v-if="logoWhiteoutUrl" :src="logoWhiteoutUrl" alt="")
						figcaption
							strong(data-label="Logo / Whiteout") Logo / Whiteout
							span Accepts: SVG or PNG ({{maxFileSizeTidy}})
							+svg(svg-filename="iconUpload" aria-hidden="true" alt="Upload icon")
					DropZone(
						ref="dropzoneWhiteout"
						:paramName="dzOpts.paramName"
						:url="dzOpts.url"
						:withCredentials="dzOpts.withCredentials"
						:uploadOnDrop="dzOpts.uploadOnDrop"
						:multipleUpload="dzOpts.multipleUpload"
						:maxFiles="dzOpts.maxFiles"
						:parallelUpload="dzOpts.parallelUpload"
						:acceptedFiles="dzOpts.acceptedFiles.logo"
						:maxFileSize="maxFileSize"
						@addedFile="onLogoWhiteoutAdd"
						@sending="onLogoWhiteoutSend"
					)
			div
				div.field.upload.hero
					button.bin.but.circ(v-if="heroUrl" type="button" @click="onMediaBin('dropzoneHero')")
						+svg(svg-filename="iconBin" aria-hidden="true" alt="Bin icon")
					figure
						img(v-if="heroUrl" :src="heroUrl" alt="")
						figcaption
							strong 
							strong(data-label="Hero Image") Hero Image
							span Accepts: JPG ({{maxFileSizeTidy}})
							+svg(svg-filename="iconUpload" aria-hidden="true" alt="Upload icon")
					DropZone(
						ref="dropzoneHero"
						:paramName="dzOpts.paramName"
						:url="dzOpts.url"
						:withCredentials="dzOpts.withCredentials"
						:uploadOnDrop="dzOpts.uploadOnDrop"
						:multipleUpload="dzOpts.multipleUpload"
						:maxFiles="dzOpts.maxFiles"
						:parallelUpload="dzOpts.parallelUpload"
						:acceptedFiles="dzOpts.acceptedFiles.hero"
						:maxFileSize="maxFileSize"
						@addedFile="onHeroAdd"
						@sending="onHeroSend"
					)
		div.wrapper
			div.c2
				h2.sub Settings
				div.field
					Field(name="priStyle" as="select" placeholder="Unsupplied" autocomplete="off" v-model="priStyle" :validateOnInput="false" v-slot="{value}" :class="{empty:!values.priStyle}" @change="enableCheck")
						option(v-for="(style, index) in butStyles" :value="style" :selected="style&&style===priStyle") {{ucfirst(style)}}
					label Primary Button / Style
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				div.field
					Field(name="secStyle" as="select" placeholder="Unsupplied" autocomplete="off" v-model="secStyle" :validateOnInput="false" v-slot="{value}" :class="{empty:!values.secStyle}" @change="enableCheck")
						option(v-for="(style, index) in butStyles" :value="style" :selected="style&&style===secStyle") {{ucfirst(style)}}
					label Secondary Button / Style
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				div.field
					Field(name="radiusBox" as="select" placeholder="Unsupplied" autocomplete="off" v-model="radiusBox" :validateOnInput="false" v-slot="{value}" :class="{empty:!values.radiusBox}" @change="enableCheck")
						option(value="0" :selected="radiusBox===0") Square
						option(v-for="num in 15" :value="num+'px'" :selected="num&&num===radiusBox") {{num}} pixels
					label Radius / Panel
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				div.field
					Field(name="radiusButton" as="select" placeholder="Unsupplied" autocomplete="off" v-model="radiusButton" :validateOnInput="false" v-slot="{value}" :class="{empty:!values.radiusButton}" @change="enableCheck")
						option(value="0" :selected="radiusButton===0") Square
						option(v-for="num in 15" :value="num+'px'" :selected="num&&num===radiusButton") {{num}} pixels
					label Radius / Buttons
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				div.field
					Field(name="radiusUser" as="select" placeholder="Unsupplied" autocomplete="off" v-model="radiusUser" :validateOnInput="false" v-slot="{value}" :class="{empty:!values.radiusUser}" @change="enableCheck")
						option(value="50%" :selected="radiusUser==='50%'") Circular
						option(value="0" :selected="radiusUser===0") Square
						option(v-for="num in 15" :value="num+'px'" :selected="num&&num===radiusUser") {{num}} pixels
					label Radius / User
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				div.field
					Field(name="radiusLi" as="select" placeholder="Unsupplied" autocomplete="off" v-model="radiusLi" :validateOnInput="false" v-slot="{value}" :class="{empty:!values.radiusLi}" @change="enableCheck")
						option(value="0" :selected="radiusLi===0") Square
						option(v-for="num in 12" :value="num+'px'" :selected="num&&num===radiusLi") {{num}} pixels
					label Radius / List Marker
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				div.field
					Field(name="radiusHr" as="select" placeholder="Unsupplied" autocomplete="off" v-model="radiusHr" :validateOnInput="false" v-slot="{value}" :class="{empty:!values.radiusHr}" @change="enableCheck")
						option(value="0" :selected="radiusHr===0") Square
						option(v-for="num in 25" :value="num+'px'" :selected="num&&num===radiusHr") {{num}} pixels
					label Radius / Divider Marker
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				div.field
					input(type="checkbox" v-model="boxShadow" value="true" @change="enableCheck")
					label Panel / Shadows
					span.on(v-if="boxShadow") On
					span.off(v-else) Off
				div.field
					Field(name="iconStyle" as="select" placeholder="Unsupplied" autocomplete="off" v-model="iconStyle" :validateOnInput="false" v-slot="{value}" :class="{empty:!values.iconStyle}" @change="enableCheck")
						option(v-for="(style, index) in butStyles" :value="style" :selected="style&&style===iconStyle") {{ucfirst(style)}}
					label Icon / Style
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
			div.c2
				h2.sub Signup / Signin Screens
				
				FieldText.grow(name="priStrapline" label="Strapline / Primary" v-model="priStrapline" :required="true" :cancelled="cancelled" :errors="errors" @keyup="enableCheck" @blur="enableCheck")
				
				FieldText.grow(name="secStrapline" label="Strapline / Secondary" v-model="secStrapline" :required="false" :cancelled="cancelled" :errors="errors" @keyup="enableCheck" @blur="enableCheck")
				
				div.field
					input(type="checkbox" v-model="cta" value="true" @change="enableCheck")
					label CTA Button
					span.on(v-if="cta") Enabled
					span.off(v-else) Disabled
					
				FieldText(name="ctaText" label="CTA Button / Text" v-model="ctaText" :required="true" :cancelled="cancelled" :errors="errors" @keyup="enableCheck" @blur="enableCheck")
				
				FieldText.grow(name="ctaUrl" label="CTA Button / URL" v-model="ctaUrl" :required="true" :cancelled="cancelled" :errors="errors" @keyup="enableCheck" @blur="enableCheck")
				
				div.field
					input(type="checkbox" v-model="ctaWindow" value="true" @change="enableCheck")
					label CTA / Browser Window
					span.on(v-if="ctaWindow") Open in new window
					span.off(v-else) Open in same window					
					
				div.field
					input(type="checkbox" v-model="allowSignup" value="true" @change="enableCheck")
					label User Sign Up
					span.on(v-if="allowSignup") Enabled
					span.off(v-else) Disabled
</template>

<script>
import { DropZone } from 'dropzone-vue';
import Search from '../components/Search';
import FieldText from '../components/FieldText';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import { mapFields } from 'vuex-map-fields';
	
export default {
	name: 'ManageThemeStyle',
	props: ['cancelled'],
	emits: ['storeUpdated'],
	components: {
		DropZone,
		Search,
		FieldText,
		Form,
		Field,
		ErrorMessage,
	},
	data() {
		const apiBaseUrl = (process.env.VUE_APP_VUE_ENV === 'development') ? process.env.VUE_APP_API_BASE_URL : window.location.origin + '/api';
		const req = 'Required';
		const inv = 'Invalid';
		const styleSchema = Yup.object().shape({
			priStrapline: Yup.string().required(req).nullable(),
			secStrapline: Yup.string().nullable(),
			ctaText: Yup.string().required(req).nullable(),
			ctaUrl: Yup.string().url(inv).required(req).nullable(),
		});
		
		return {
			styleSchema,
			showModal: false,
			previewIndex: 0,
			butStyles: ['solid','outline'],			
			// images
			dzOpts: {
//				url: process.env.VUE_APP_API_BASE_URL + '/media/theme',
				url: apiBaseUrl + '/media/theme',
				withCredentials: true, // pass cookies
				paramName: 'file', // match to multerUpload
				uploadOnDrop: true,
				multipleUpload: false,
				maxFiles: 1,
				parallelUpload: 1,
				maxFileSize: 2000000, // 2 MB
				acceptedFiles: {
					logo: ['svg','png'],
					hero: ['jpg'],
				},
			},
		};
	},
	mounted() {
		this.$store.dispatch('gui/setHeader', {			
			title: this.terminology('interface', 'manage', 'singular') + ' ' + this.terminology('theme','theme','singular'),
			backRoute: {
				text: 'Dashboard',
				name: 'Dashboard',
			},			
		});
	},
	computed: {
		...mapFields('gui', {
			// settinga
			priSolid: 'cssClass.pri-solid',			
			priOutline: 'cssClass.pri-outline',			
			secSolid: 'cssClass.sec-solid',			
			secOutline: 'cssClass.sec-outline',
			radiusBox: 'cssStyle.radius.box',
			radiusButton: 'cssStyle.radius.button',			
			radiusUser: 'cssStyle.radius.user',
			radiusLi: 'cssStyle.radius.li',
			radiusHr: 'cssStyle.radius.hr',
			boxShadow: 'cssClass.box-shadow',
			iconFill: 'icon.fill',
			// signup / signin screens
			priStrapline: 'authScreen.strapline.pri',
			secStrapline: 'authScreen.strapline.sec',
			cta: 'authScreen.cta.enable',
			ctaText: 'authScreen.cta.text',
			ctaUrl: 'authScreen.cta.url',
			ctaWindow: 'authScreen.cta.window',
			allowSignup: 'authScreen.allowSignup',
			// brand media
			awsS3KeyColour: 'organisation.awsS3KeyColour',
			awsS3KeyWhiteout: 'organisation.awsS3KeyWhiteout',
			awsS3Keyhero: 'organisation.awsS3Keyhero',
			// 
			logoColourUrl: 'organisation.logoColourUrl',
			logoWhiteoutUrl: 'organisation.logoWhiteoutUrl',
			heroUrl: 'organisation.heroUrl',
		}),
		priStyle: {
			get() {
				return this.priSolid ? 'solid' : 'outline';
			},
			set(style) {
				if (style === 'solid') {
					this.priSolid = true;
					this.priOutline = false;
				} else {
					this.priSolid = false;
					this.priOutline = true;					
				}
			},
		},
		secStyle: {
			get() {
				return this.secSolid ? 'solid' : 'outline';
			},
			set(style) {
				if (style === 'solid') {
					this.secSolid = true;
					this.secOutline = false;
				} else {
					this.secSolid = false;
					this.secOutline = true;					
				}
			},
		},
		iconStyle: {
			get() {
				return this.iconFill ? 'solid' : 'outline';
			},
			set(style) {
				this.iconFill = (style === 'solid') ? 1 : 0;
			},
		},
		maxFileSize() {
			const sizes = this.$store.getters['gui/maxFileSizes'];
			return sizes['image'].max || 0;
		},
		maxFileSizeTidy() {
			return this.formatBytes(this.maxFileSize, 1);
		},
	},
	methods: {
		formatBytes(bytes, decimals = 1) {
			if (bytes === 0) return '0 Bytes';

//			const k = 1024;
			const k = 1000;
			const dm = decimals < 0 ? 0 : decimals;
			const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

			const i = Math.floor(Math.log(bytes) / Math.log(k));

			return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
		},
		ucfirst(str) {
			return str.charAt(0).toUpperCase() + str.slice(1);
		},
		enableCheck() {
			const meta = this.$refs.styleForm.getMeta();
			this.$emit('storeUpdated', {
				screen: 'style',
				valid: meta.valid,
			});			
		},
		onLogoColourSend(file, xhr, formData) {
			formData.append('key', 'colour');
		},
		onLogoWhiteoutSend(file, xhr, formData) {
			formData.append('key', 'whiteout');
		},
		onHeroSend(file, xhr, formData) {
			formData.append('key', 'hero');
		},
		onLogoColourAdd(item) {
			this.onMediaAdd('logoColourUrl', item);
		},
		onLogoWhiteoutAdd(item) {
			this.onMediaAdd('logoWhiteoutUrl', item);
		},
		onHeroAdd(item) {
			this.onMediaAdd('heroUrl', item);
		},
		onMediaAdd(param, item) {
			this[param] = item.file.name;			
			
			this.$emit('storeUpdated', {
				screen: 'style',
				valid: true,
			});			
			
		},
		onMediaBin(ref) {
			this.$refs[ref].removeFile(this.$refs[ref].ids[0]);
			
			if (ref === 'dropzoneHero') {
				this.heroUrl = null;
				
			} else if (ref === 'dropzoneColour') {
				this.logoColourUrl = null;
				
			} else if (ref === 'dropzoneWhiteout') {
				this.logoWhiteoutUrl = null;
			}
			
			this.$emit('storeUpdated', {
				screen: 'style',
				valid: true,
			});			
		},
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
	},
}
</script>

<style lang="scss">
#style {
	.field.logo,
	.media >div:nth-child(2):before { // hero container
		min-height: 200px;
		max-width: none;
	}
	.field.logo {
		figure {
			position: relative;
			background: inherit;
		}
		img {
			object-fit: contain;
			background: inherit;
			padding: 20px;
		}
		.dropzone--has-thumbnail {
			padding: 10px;
		}
		.bin + figure figcaption strong:after {
			transform: translate(-12px, -12px);
		}
		&.colour {
			.bin + figure figcaption strong:after {
				color: var(--lab-col);
			}
		}
		&.whiteout {
			.bin + figure figcaption strong:after {
				//background: var(--pan-bar-bg);
			}
			img {
				//filter: brightness(0) invert(1);
			}
			&.on,
			.dropzone__item {
				background: var(--pan-bar-bg);
			}
		}
	}
	.field.hero {
		max-width: none;
	}
	.wrapper,
	.c2 {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.c2 { // nested
		>div {
			width: calc(50% - 5px);
		}
	}
	.wrapper {
		.sub {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 0 10px;
			margin: 20px 0 10px 0;
			min-height: 30px;
			font-size: 1.6rem;
			color: var(--sub-col);
			font-weight: var(--sub-wgt);
		}
	}
	@media only screen and (max-width:539px) {
		.wrapper {
			.c2 {
				>div {
					width: 100%;
				}
				.field {
					max-width: none;
				}
			}
			&.media {
				.field {
					aspect-ratio: 16/9;
					&.hero {
						margin-top: -10px;
					}
				}
			}
		}
	}
	@media only screen and (min-width:540px) {
		.field.logo,
		.media >div:nth-child(2):before { // hero container
			aspect-ratio: 4/3;
			min-height: 200px;
		}
		.wrapper {
			&.media {
				align-items: stretch;
				>div {
					&:nth-child(2) { // hero
						position: relative;
						&:before {
							content: '';
							display: block;
							width: calc(50% - 5px); // match nested
						}
						.field {
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
						}
					}
				}
				.field {
					margin-bottom: 0;
				}
			}
			>div {
				width: calc(50% - 10px);
			}
		}
	}
	@media only screen and (max-width:839px) {
		.wrapper {
			flex-wrap: wrap;
			>div {
				width: 100%;
			}
			&.media {
				>:nth-child(2) {
					margin-top: 10px;
				}
			}
		}
	}
}
</style>
